import clsx from "clsx"
import createStyle from "lib/createStyle"

export const SerifInputEl = createStyle(
  "input",
  clsx(
    "block w-full",
    "bg-[#FFFFFF0A] font-sans peer text-[1rem]",
    "p-3.5 bg-white/5 rounded-xl justify-start items-center gap-1 inline-flex",
    "placeholder-cream placeholder-opacity-40",
    "focus:bg-[#FFFFFF0A] focus:border-opacity-100 focus:outline-none",
    "disabled:text-medgray read-only:text-medgray",
    "transition duration-300",
    "autofill-transparent_auto",
    "appearance-none"
  )
)

export const SerifLabel = createStyle(
  "label",
  "block text-[0.875rem] tracking-[0.01em] leading-[1.2] text-swisscafe"
)
