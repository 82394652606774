import { forwardRef, useState } from "react"
import clsx from "clsx"

import type { InputProps } from "components/Forms/types"
import TooltipWrapper from "@/components/Tooltip"
import EyeOffIcon from "icons/EyeOff"
import EyeIcon from "icons/Eye"
import InfoIcon from "@/icons/Info"

import { SerifInputEl, SerifLabel } from "./sansStyles"

export const SerifInput = forwardRef<HTMLElement, InputProps>(
  (
    {
      name,
      // label,
      description,
      ...props
    },
    ref
  ) => {
    const id = props.id || `${name}-input`
    const [isRevealed, setIsRevealed] = useState(false)

    const inputStyle =
      props.type === "password" && !isRevealed
        ? { fontFamily: "sans-serif" }
        : { fontFamily: "sans-serif" }

    return (
      <div className="w-full">
        <div
          className={clsx(
            "overflow-hidden mb-1",
            props.type === "hidden" && "sr-only"
          )}
        >
          <SerifLabel
            htmlFor={id as string}
            className={clsx(
              !props.value && "translate-y-full",
              "transition duration-300"
            )}
          >
            {/* {label}
            {props.required && " *"} */}
            {description && (
              <TooltipWrapper tooltip={description}>
                <InfoIcon />
              </TooltipWrapper>
            )}
          </SerifLabel>
        </div>
        <div className="flex items-start">
          <SerifInputEl
            id={id}
            name={name}
            ref={ref}
            {...props}
            style={inputStyle}
            // placeholder={`${props.placeholder || label}${
            //   props.required ? "*" : ""
            // }`}
            type={isRevealed ? "text" : props.type || "text"}
          />
          {props.type === "password" && (
            <button
              type="button"
              className="p-2 -ml-10 -mr-4 opacity-50 w-14 h-2 mb-2 pt-[18px]"
              onClick={() => setIsRevealed((r) => !r)}
              tabIndex={-1}
            >
              <span className="sr-only">Reveal password field</span>
              {isRevealed ? (
                <EyeIcon width={16} height={16} />
              ) : (
                <EyeOffIcon width={16} height={16} />
              )}
            </button>
          )}
        </div>
      </div>
    )
  }
)
export default SerifInput
